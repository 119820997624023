import React from 'react'
import style from './App.module.css'

const survey = {
  id: "27601C4D-C887-45D9-9BD2-7F15E233EE1D",
  intro: "We would like to learn more about the costs of meetups, trade shows, gatherings, professional meetings, conferences, and events people attend over a year, for any purpose other than attending classes at school.",
  questions: [{
    id: "2A3273AE-83B5-46E2-A6A0-F66AE13CCB4B",
    text: "How would you describe the type of metropolitan area where you live?",
    answer: {
      type: "choose-one",
      from: ["Dense City", "City", "Suburb", "Rural"]
    }
  }, {
    id: "38713CD2-9328-4607-8FF4-1DCAB06B3BBF",
    text: "What type of events are you typically attending? (pick all that apply)",
    answer: {
      type: "choose-many",
      from: ["Professional","Community","Entertainment","Social","Political"]
    }
  }, {
    id: "BED3ECE9-2871-4933-9652-361A050AF8C0",
    text: "How many events do you attend each year that are free?",
    answer: {
      type: "choose-one",
      from: ["None", "less than 3", "3-10", "11-20", "More than 20"]
    }
  }, {
    id: "F50CC21A-4A0D-4099-8082-2F919BD450D5",
    text: "How many events do you attend each year that charge a ticket price less than $200?",
    answer: {
      type: "choose-one",
      from: ["None", "less than 3", "3-10", "11-20", "More than 20"]
    }
  }, {
    id: "39FCEED3-72FB-4919-842E-B0A72B761E19",
    text: "How many events do you attend each year that charge a ticket price greater than $200?",
    answer: {
      type: "choose-one",
      from: ["None", "less than 3", "3-10", "11-20", "More than 20"]
    }
  }, {
    id: "41E9D493-B70F-4C9A-BED4-48957AADEA35",
    text: "Roughly speaking, what is the total spent for you to attend these events over a year? (include the normal ticket price, even if your ticket was free)",
    answer: {
      type: "input",
      label: "USD"
    }
  }]
}

function format_choose_one(conn, question) {
    return question.answer.from.map((id, index) => {
      const value = question.answer.from[index]
      return (
        <div className="pl3">
          <label class="f6 mr2 ml2" for={question.id}>{value}</label>
          <input
            type="radio"
            name={question.id}
            value={value}
          />
        </div>
      )
    })
}

function format_choose_many(conn, question) {
    return question.answer.from.map((id, index) => {
      const value = question.answer.from[index]
      return (
        <div className="pl3">
          <label class="f6 mr2 ml2" for={question.id}>{value}</label>
          <input
            type="checkbox"
            name={question.id}
            value={value}
          />
        </div>
      )
    })
}

function format_input(conn, question) {
      return (
        <div className="pl3">
          <label class="f6 mr2 ml2" for={question.id}>{question.answer.label}</label>
          <input
            type="input"
            name={question.id}
          />
        </div>
      )
}

function format_inputs(conn, question) {
  switch (question.answer.type) {
    case "choose-one":
      return format_choose_one(conn, question)
    case "choose-many":
      return format_choose_many(conn, question)
    case "input":
      return format_input(conn, question)
    default:
      console.log("You have a typo in your questions")
      return (<></>)
  }
}

function Question(props) {
  return format_inputs(props.conn, props.data)
}

// base class
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {questions: {}}
    for (let question in survey.questions) {
      console.log(question)
    }
  }

  saveAnswer(e, info) {
    this.setState({ loading: true })
    e.preventDefault()
    let vars = {
      signup: '',
      factor: 'email', // in the future: let people choose factors to auth with
      handle: '',
      password: '',
      email: ''
    }


    /*
    if (vars.handle.length === 0) {
      this._setStatus('Please provide an email address!', 'red')
      return false
    }
    if (vars.password.length === 0) {
      this._setStatus('Please provide a password!', 'red')
      return false
    }
    this.setState({ handle: '', password: '' })
    */
    const { user } = this.props.context
    this._request('signon', {
      body: JSON.stringify(vars)
    })
      .then((data) => {
        if (data.aud && data.sec && data.sub) {
          user.setValidation({
            audience: data.aud,
            secret: data.sec,
            subject: data.sub
          })
          user.refreshAccessToken()
        } else if (data.reason) {
          this.setState({ loading: false, status: { message: data.reason, color: 'red' } })
        } else {
          let msg = 'response received from backend with no validation token? cannot continue'
          this.setState({ loading: false, status: { message: msg, color: 'red' } })
        }
      })
      .catch((err) => {

        // TODO: move this to generic module for all things
        if (err.message) {
          const cleaned = err.message.replace(/GraphQL error: /, '')
            .replace(/Network error: Unexpected token < in JSON /, 'Unexpected response from backend, cannot continue, sorry!')
            .replace(/Network error: Failed to fetch/, 'Unable to talk to backend, it may be down, sorry!')
            .replace(/^Network error: /, '')
            .replace(/Server /i, 'backend ')

          this.setState({ loading: false, status: { message: cleaned, color: 'red' } })
        } else {
          this.setState({ loading: false, status: { message: 'unknown error', color: 'red' } })
        }
      })
  }

  render () {
    // let { handle, password } = this.state
    return (
      <div className="flex justify-center">
        <div className="mt6-l mt3-m mt2 w-70-l w-90-m">
          <div className={`${style.panel} pa4-l pa3-m pa1`}>
            <div className='pa3 f2 lh-copy fw1 tc'><i>Meetups and Conferences Survey</i></div>
            <div className='pb5 f5 lh-copy fw1 tc'>
            <i>We know your day is busy, and we are grateful you are willing to spend some time helping us.</i>
            <p className='pt3'/>
            {survey.intro}
            <p className='pt3'/>
            {survey.questions.map((id, index) => {
              const question = survey.questions[index]
              return (
                  <div id={question.id} className={`bg-white tl mt3-l mt2 pa3`}>
                    <div className="flex">
                      <div>{question.text}</div>
                    </div>
                    <form>
                      <div className="flex items-start justify-center pt3 pb3">
                        <Question conn={this} data={question} />
                      </div>
                    </form>
                  </div>
                )
              }
            )}

              <div className="mt3-l mt2 pa3 bg-white">
               Thank You, your answers have been recorded!
              </div>
              <div className="mt3-l mt2 pa3 bg-white">
               If you are willing, we have a few more questions...
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App
