import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import CurrentUser from './CurrentUser.js'
import debug from './debug'
import config from './config'
import { sleep } from './utils/time'
/* drag-n-drop */
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

const user = new CurrentUser()
const httpLink = createHttpLink({
  uri: config.baseurl + config.graphql,
  credentials: 'same-origin'
})
const authLink = setContext(async (_, { headers }) => {
  let token = user.getAccessToken()
  // https://www.apollographql.com/docs/angular/recipes/authentication/#waiting-for-a-refreshed-token
  // fugly hack, we'll remove after selecting a final auth system
  do {
    await sleep(500)
    token = user.getAccessToken()
  } while (!token)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            /*            const headers = operation.getContext().headers
            operation.setContext({
              headers: {
                ...headers,
                authorization: user.refreshAccessToken(),
              },
            });
            */
            debug('[<root index>] Error')
            user.refreshAccessToken()
            // Now, pass the modified operation to the next link
            // in the chain. This effectively intercepts the old
            // failed request, and retries it with a new token
            return forward(operation)
          default:
            debug('[<root index>] errors', err)
            return forward(operation)
        }
      }
    }
  }
})
user.setClient(client) // so we can clear it on logout

// ReactDOM.render(<App />, document.getElementById('root'))

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <DndProvider backend={HTML5Backend}>
        <App user={user} apollo={client} config={config} />
      </DndProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
serviceWorker.unregister()

/*
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
*/
