function timeDifference(current, previous) {
  const milliSecondsPerMinute = 60 * 1000
  const milliSecondsPerHour = milliSecondsPerMinute * 60
  const milliSecondsPerDay = milliSecondsPerHour * 24
  const milliSecondsPerMonth = milliSecondsPerDay * 30
  const milliSecondsPerYear = milliSecondsPerDay * 365

  const elapsed = current - previous

  if (elapsed < milliSecondsPerMinute / 3) {
    return 'just now'
  }

  if (elapsed < milliSecondsPerMinute) {
    return 'less than 1 min ago'
  }
  if (elapsed < milliSecondsPerHour) {
    return `${Math.round(elapsed / milliSecondsPerMinute)} min ago`
  }
  if (elapsed < milliSecondsPerDay) {
    return `${Math.round(elapsed / milliSecondsPerHour)} h ago`
  }
  if (elapsed < milliSecondsPerMonth) {
    return `${Math.round(elapsed / milliSecondsPerDay)} days ago`
  }
  if (elapsed < milliSecondsPerYear) {
    return `${Math.round(elapsed / milliSecondsPerMonth)} mo ago`
  }
  return `${Math.round(elapsed / milliSecondsPerYear)} years ago`
}

export function secondsForHuman(seconds) {
  if (seconds > 86400) {
    return `${Math.round(seconds / 86400)}d`;
  } else if (seconds > 3600) {
    return `${Math.round(seconds / 3600)}h`;
  } else {
    return `${Math.round(seconds / 60)}m`;
  }
}

export function timeDifferenceForDate(date) {
  const now = new Date().getTime()
  const updated = new Date(date).getTime()
  return timeDifference(now, updated)
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// receive whatever they type, make sure it's what we want, returning
// both the server-server format, and the user-format
export function parseInputTime(str) {
    
}
