import jwt from 'jsonwebtoken'
import uuid from 'uuid/v4'
import { Base64 } from 'js-base64'
import debug from './debug'
import { EventEmitter } from 'events'
import { AUTH_SIGNIN, AUTH_SIGNOUT } from './constants/Auth'
import nacl from 'tweetnacl' // cryptographic functions
import util from 'tweetnacl-util' // encoding & decoding

export const PUBKEY = 'LkgFIzUhudr3xtY1UK0zZaSoD89KhAra9a+4BS/QE3A='
export const PRVKEY = 'kByhcUytynXFAsjKZVfhfu1NAyc307SdcY4VhwsDhJs='
export const VALIDATION_KEY = 'pdv'
export const ACCESS_KEY = 'pda'

/// /////////////////////////////////////////////////////////////////////////////
function encrypt (receiverPublicKey, msgParams) {
  debug('[].encrypt', '()')
  const ephemeralKeyPair = nacl.box.keyPair()
  const pubKeyUInt8Array = util.decodeBase64(receiverPublicKey)
  const msgParamsUInt8Array = util.decodeUTF8(msgParams)
  const nonce = nacl.randomBytes(nacl.box.nonceLength)
  const encryptedMessage = nacl.box(
    msgParamsUInt8Array,
    nonce,
    pubKeyUInt8Array,
    ephemeralKeyPair.secretKey
  )
  return {
    ciphertext: util.encodeBase64(encryptedMessage),
    ephemPubKey: util.encodeBase64(ephemeralKeyPair.publicKey),
    nonce: util.encodeBase64(nonce),
    version: 'x25519-xsalsa20-poly1305'
  }
}

/// /////////////////////////////////////////////////////////////////////////////
// Decrypt a message with a base64 encoded secretKey (privateKey)
function decrypt (receiverSecretKey, encryptedData) {
  debug('[].decrypt', '()')
  const receiverSecretKeyUint8Array = util.decodeBase64(
    receiverSecretKey
  )
  const nonce = util.decodeBase64(encryptedData.nonce)
  const ciphertext = util.decodeBase64(encryptedData.ciphertext)
  const ephemPubKey = util.decodeBase64(encryptedData.ephemPubKey)
  const decryptedMessage = nacl.box.open(
    ciphertext,
    nonce,
    ephemPubKey,
    receiverSecretKeyUint8Array
  )
  return util.encodeUTF8(decryptedMessage)
}

/// /////////////////////////////////////////////////////////////////////////////
function safeStoreDrop (key) {
  debug('[].safeStoreDrop key=', key)
  localStorage.removeItem(key)
}

function safeStorePut (key, data) {
  debug('[].safeStorePut key=', key)
  const raw = encrypt(PUBKEY, JSON.stringify(data))
  const vers = 'v' // any single character as a version ID
  const encoded = vers + Base64.encode(`${raw.nonce}:${raw.version}:${raw.ciphertext}:${raw.ephemPubKey}`)
  localStorage.setItem(key, encoded)
  return encoded
}

function safeStoreGet (key) {
  debug('[].safeStoreGet key=', key)

  const data = localStorage.getItem(key)
  try {
    const vers = data.slice(0, 1)
    const decoded = Base64.decode(data.slice(1)).split(':')
    switch (vers) { // future proof schemes
      case 'v':
        const raw = {
          nonce: decoded[0],
          version: decoded[1],
          ciphertext: decoded[2],
          ephemPubKey: decoded[3]
        }
        return JSON.parse(decrypt(PRVKEY, raw))
      default:
        return undefined
    }
  } catch (err) {
    return undefined
  }
}

/// /////////////////////////////////////////////////////////////////////////////
class CurrentUser {
  constructor () {
    debug('[].constructor', '()')
    this.event = new EventEmitter()
    this.access_token = undefined
    this.access_token_expires = 0
    this.validation_token = undefined // this is stored locally, so don't remove
    this.client = undefined
  }

  // generate a refresh token from the validation information
  genRefreshToken () {
    if (this.validation_token) {
      debug('[].genRefreshToken, Requesting Refresh Token, via validation token', this.validation_token)
      const { secret, subject, audience } = this.validation_token
      return jwt.sign({
        jti: uuid(),
        sub: subject,
        aud: audience
      }, secret, { expiresIn: 10 * 60 })
    }
    debug('[].genRefreshToken, no validation, cannot generate refresh', '()')
  }

  setClient (client) {
    debug('[].setClient', '()')
    this.client = client
  }

  setValidation (token) {
    debug('[].setValidation', token)
    let { audience, secret, subject } = token
    if (audience && secret && subject) {
      safeStorePut(VALIDATION_KEY, token)
      this.validation_token = token
    }
  }

  removeValidation () {
    debug('[].removeValidation', '()')
    this.validation_token = undefined
    safeStoreDrop(VALIDATION_KEY)
  }

  getValidation () {
    debug('[].getValidation', '()')
    if (!this.validation_token) {
      let validation
      try {
        validation = safeStoreGet(VALIDATION_KEY)
        if (validation) {
          if (!validation.audience) {
            debug('[].getValidation, Bad audience', 1)
            this.removeValidation()
          }
        }
        this.validation_token = validation
      } catch (err) {
        debug('[].getValidation, Oops', err)
      }
      debug('[].getValidation, GET VALIDATION (cached)', validation)
      if (!validation) {
        return undefined
      } else {
        this.validation_token = validation
      }
    }
    debug('[].getValidation, return = ', this.validation_token)
    return this.validation_token
  }

  // get a current access token
  getAccessToken () {
    debug('[].getAccessToken', '()')
    // TODO: insert a check against local storage
    if (!this.access_token) {
      const token = safeStoreGet(ACCESS_KEY)
      if (token) {
        if (this.keepAccessToken(token)) {
          debug('[].getAccessToken =>', this.access_token)
          return this.access_token
        }
        safeStoreDrop(ACCESS_KEY)
      }
    } else {
      if (this.access_token_expires > Date.now()) {
        debug('[].getAccessToken =>', this.access_token)
        return this.access_token
      } else {
        this.removeAccessToken()
      }
    }
    debug('[].getAccessToken =>', undefined)
    return undefined
  }

  // readbility simplification,
  keepAccessToken (token) {
    const claims = jwt.decode(token)
    if ((Date.now() / 1000) < claims.exp) {
      this.access_token = token
      this.access_token_expires = claims.exp * 1000
      return true
    }
  }

  setAccessToken (token) {
    debug('[].setAccessToken', '(token)')
    if (this.keepAccessToken(token)) {
      safeStorePut(ACCESS_KEY, token)
    }
  }

  removeAccessToken (token) {
    debug('[].removeAccessToken', '(token)')
    safeStoreDrop(ACCESS_KEY)
    this.access_token = undefined
    this.access_token_expires = 0
  }

  signOut () {
    debug('[].signOut', '()')
    this.removeValidation()
    this.removeAccessToken()
    if (this.client) {
      this.client.cache.reset()
    }
    this.event.emit(AUTH_SIGNOUT)
  }

  signedIn () {
    debug('[].signedIn', '()')
    if (this.getAccessToken()) {
      debug('[].signedIn =>', true)
      return true
    }
    debug('[].signedIn =>', false)
    return false
  }

  setAuthComponent (component) {
    debug('[].setAuthComponent() =', component)
    this.auth_component = component
  }

  removeAuthComponent () {
    debug('[].removeAuthComponent', '()')
    this.auth_component = undefined
  }

  /// ///////////////////////////////////////////////////////////////////////////
  async refresh () {
    debug('[].refresh<async> ()')

    // check age of access token; if it's good, we don't need to keep going
    let access = false
    if (this.access_token) {
      const claims = jwt.decode(this.access_token)
      if ((Date.now() / 1000) < claims.exp) {
        access = this.access_token
      }
    }
    if (access) {
      // todo: call token verification on server
      debug('[].refresh<async> =>', 'already have token => true')
      this.event.emit(AUTH_SIGNIN) // downstream: does a redirect
      return true
    }

    // do we have a good validation we can work with to request a new access token?
    const validation = this.getValidation()
    if (!validation) {
      debug('[].refresh<async> =>', 'no access token, no validation, no auth => false')
      // nope; externally this should redirect to a signin page via events
      return false
    }

    // do we know what our auth component is?  This is our signin page.
    if (this.auth_component) {
      return this.refreshAccessToken()
    } else {
      debug('[].refresh<async> =>', 'cannot auth, missing auth_component? => false')
      return false
    }
  }

  request (queryPath, args) {
    debug('[].request ', queryPath)
    return this.auth_component._request(queryPath, args)
  }

  /// ///////////////////////////////////////////////////////////////////////////
  // start a signon from the login form
  signOn (vars) {
    debug('[].signOn', '()')
    this.request('signon', {
      body: JSON.stringify(vars)
    })
      .then(this.handleSignin)
      .catch(this.handleError)
  }

  handleSignin = async (data) => {
    debug('[].then(.handleSignin)<async> data=', data)
    if (data.aud && data.sec && data.sub) {
      this.setValidation({
        audience: data.aud,
        secret: data.sec,
        subject: data.sub
      })
      this.refreshAccessToken()
    } else if (data.reason) {
      this.error(data.reason)
    } else {
      this.error('response received from backend with no validation token? cannot continue')
    }
  }

  handleError (res) { // = async (res) => {
    debug('[].catch(.handleError)<async> res=', res)

    // TODO: move this to generic module for all thingres
    if (res.message) {
      const cleaned = res.message.replace(/GraphQL error: /, '')
        .replace(/Network error: Unexpected token < in JSON /, 'Unexpected response from backend, cannot continue, sorry!')
        .replace(/Network error: Failed to fetch/, 'Unable to talk to backend, it may be down, sorry!')
        .replace(/^Network error: /, '')
        .replace(/Server /i, 'backend ')

      this.error(cleaned)
      debug('<async> [.catch(CurrentUser.handleError)] message=', cleaned)
    }
  }

  // after validation, do access
  refreshAccessToken = async () => {
    debug('[].refreshAccessToken<async>', '()')
    if (this.validation_token) {
      this.request('refresh', {
        body: JSON.stringify({
          'client_assertion_type': 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
          'client_assertion': this.genRefreshToken()
        })
      })
        .then((data) => {
          debug('[].handleRefreshRequest data=', data)
          if (data.access_token) {
            this.setAccessToken(data.access_token)
            this.event.emit(AUTH_SIGNIN) // downstream: does a redirect
          } else {
            this.error('unexpected result from backend')
          }
        })
        .catch((err) => {
          debug('[].refreshAccessToken: error during re-auth', err)
          this.handleError(err)
          this.signOut()
        })
    } else {
      this.notify('Unable to find validation token!', 'red')
    }
  }

  // utility pass-thru for simpler reading
  notify (msg) {
    if (this.auth_component) {
      this.auth_component._setStatus(msg)
    } else {
      console.log('lost login msg', msg)
    }
  }

  error (msg) {
    console.log('error', msg)
    if (this.auth_component) {
      this.auth_component._setStatus(msg, 'red')
    }
  }
}

export default CurrentUser
