let config = {
  baseurl: 'http://localhost:4000',
  graphql: '/graphql',
  authapi: '/auth/v1/api/'
}
switch (process.env['NODE_ENV']) {
  case 'production':
    config.baseurl = ''
    break
  default:
    break
}
/*
console.log(process.env)
if (process.env['BACKEND']) {
  config.baseurl = process.env['BACKEND']
  console.log("baseurl=" + config.baseurl)
}
*/
export default config
